var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid mb-4",staticStyle:{"margin-top":"20px"}},[_c('ModalAsignacion',{attrs:{"modal":_vm.showModal,"title":_vm.titleModal,"editModal":_vm.editModal,"objectAsignacion":_vm.asignacionSelected,"StowagePlanningImplementVehicleId":_vm.StowagePlanningImplementVehicleId},on:{"update:modal":function($event){_vm.showModal=$event},"updated-modal":_vm.updateCLoseModal,"refresh-data-table":_vm.resetDataOfTable}}),_c('ModalHistoricoAsignacion',{attrs:{"modal":_vm.showModalAsignacion,"title":_vm.titleAsignacionModal,"listSelected":_vm.listSelected,"typeArray":_vm.typeArray,"objectAsignacion":_vm.asignacionSelected},on:{"update:modal":function($event){_vm.showModalAsignacion=$event},"updated-modal":_vm.updateCLoseModalAsignacion,"refresh-data-table":_vm.resetDataOfTable}}),_c('loading-overlay',{attrs:{"active":_vm.loadingOverlay,"is-full-page":true,"loader":"bars"}}),_c('CRow',[_c('CCol',{staticClass:"d-flex align-items-center justify-content-end mb-2",attrs:{"sm":"12"}},[_c('CButton',{staticClass:"ml-1",attrs:{"color":"excel","size":"sm"},on:{"click":function($event){return _vm.onBtnExport(true)}}},[_c('CIcon',{attrs:{"name":"cil-file-excel"}}),_vm._v("  XSLX ")],1)],1),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ 
                    placement:'top-end', 
                    content:_vm.$t('label.AssociationGamela') 
                }),expression:"{ \n                    placement:'top-end', \n                    content:$t('label.AssociationGamela') \n                }"}],attrs:{"color":"add","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.toggleShowModalCreateDriver()}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v("  "+_vm._s(_vm.$t('label.nueva'))+" ")],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ placement:'top-end', content:_vm.$t('label.AssociationGamela') }),expression:"{ placement:'top-end', content:$t('label.AssociationGamela') }"}],staticStyle:{"display":"none"}})],1),_c('CCol',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.items,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loadingTable,"hover":"","sorter":""},on:{"filtered-items-change":_vm.getFilteredList},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"DriverName",fn:function({ item }){return [_c('td',{staticClass:"text-center align-middle"},[(item.DriverName)?_c('div',[_vm._v(" ("+_vm._s(item.DriverCi)+") "+_vm._s(item.DriverName)+" ")]):_c('div',[_vm._v(" N/A ")])])]}},{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"text-center align-middle"},[_c('div',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t("label." + item.Status))+" ")])],1)])]}},{key:"Detalle",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: _vm.$t('label.historic'),
                                placement: 'top-end',
                            }),expression:"{\n                                content: $t('label.historic'),\n                                placement: 'top-end',\n                            }"}],attrs:{"color":"plan","size":"sm"},on:{"click":function($event){return _vm.toggleShowModalHistorico(item,1)}}},[_c('CIcon',{attrs:{"name":"cil-list-rich"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: _vm.$t('label.HistoryAssociation'),
                                placement: 'top-end',
                            }),expression:"{\n                                content: $t('label.HistoryAssociation'),\n                                placement: 'top-end',\n                            }"}],staticClass:"ml-1",attrs:{"color":"watch","size":"sm"},on:{"click":function($event){return _vm.toggleShowModalHistorico(item,2)}}},[_c('CIcon',{attrs:{"name":"cil-list-rich"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: _vm.$t('label.edit')+' '+_vm.$t('label.AssociationGamela'),placement:'top-end'}),expression:"{content: $t('label.edit')+' '+$t('label.AssociationGamela'),placement:'top-end'}"}],staticClass:"ml-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleShowModalEditDriver(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }