<template>
    <main>
        <CModalExtended
            :title="title"
            color="dark"
            :show.sync="modalActive"
            :closeOnBackdrop="false"
            size="xl"
            class="modal-extended"
        >
            <loading-overlay
                :active="loadingOverlay"
                :is-full-page="true"
                loader="bars"
            />
            <div>
                <div v-if="editModal&&originStatus!=0" class="d-flex justify-content-center">
                    <CCol sm="4" lg="3">
                        <CRow>
                            <label class="col-form-label col-sm-12 col-lg-8 text-right">{{ $t('label.Gamela') }}</label>
                            <div class="d-flex justify-content-end mt-1 col-sm-12 col-lg-auto pl-0">
                                <CSwitch
                                    color="watch"
                                    variant="3d"
                                    type="checkbox"
                                    :checked.sync="OrdenOpenBin"
                                />
                            </div>
                        </CRow>
                    </CCol>
                    <CCol sm="4" lg="3">
                        <CRow>
                            <label class="col-form-label col-sm-12 col-lg-8 text-right">{{ $t('label.vehicle') }}</label>
                            <div class="d-flex justify-content-end mt-1 col-sm-12 col-lg-auto pl-0">
                                <CSwitch
                                    color="watch"
                                    variant="3d"
                                    type="checkbox"
                                    :checked.sync="OrdenOpenVehicle"
                                />
                            </div>
                        </CRow>
                    </CCol>
                    <CCol sm="4" lg="3">
                        <CRow>
                            <label class="col-form-label col-sm-12 col-lg-8 text-right">{{ $t('label.yard') }}</label>
                            <div class="d-flex justify-content-end mt-1 col-sm-12 col-lg-auto pl-0">
                                <CSwitch
                                    color="watch"
                                    variant="3d"
                                    type="checkbox"
                                    :checked.sync="OrdenOpenYard"
                                />
                            </div>
                        </CRow>
                    </CCol>
                </div>
                <CRow  v-if="editModal&&(OrdenOpenBin||OrdenOpenVehicle||OrdenOpenYard)" class="justify-content-center">
                    <CCol v-if="OrdenOpenBin" sm="12" lg="4">
                        <CInput sm="12"
                            size="sm"
                            :disabled="true"
                            addLabelClasses="required text-right"
                            :placeholder="$t('label.Gamela')"
                            :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8',}"
                            :label="$t('label.Gamela')"
                            v-model.trim="objectAsignacion.ImplementAlias"
                        />
                    </CCol>
                    <CCol v-if="OrdenOpenVehicle" sm="12" lg="4">
                        <CInput sm="12"
                            size="sm"
                            :disabled="true"
                            addLabelClasses="required text-right"
                            :placeholder="$t('label.transport')"
                            :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8',}"
                            :label="$t('label.transport')"
                            v-model.trim="objectAsignacion.CarrierAlias"
                        />
                    </CCol>
                    <CCol v-if="OrdenOpenVehicle" sm="12" lg="4">
                        <CInput sm="12"
                            size="sm"
                            :disabled="true"
                            addLabelClasses="required text-right"
                            :placeholder="$t('label.vehicle')"
                            :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8',}"
                            :label="$t('label.vehicle')"
                            v-model.trim="objectAsignacion.LicensePlate"
                        />
                    </CCol>
                    <CCol v-if="OrdenOpenYard" sm="12" lg="4">
                        <CInput sm="12"
                            size="sm"
                            :disabled="true"
                            addLabelClasses="required text-right"
                            :placeholder="$t('label.yard')"
                            :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8',}"
                            :label="$t('label.yard')"
                            v-model.trim="objectAsignacion.YardName"
                        />
                    </CCol>
                </CRow>    
                <CRow class="justify-content-center">
                    <CCol sm="12" :lg="computedColumnWidth">
                        <!--div class="mt-3">
                            <CSelect
                                :label="$t('label.Gamela')"
                                :horizontal="{
                                    label: 'col-sm-12  col-lg-3',
                                    input: 'col-sm-12  col-lg-9',
                                }"
                                addLabelClasses="required text-right"
                                :disabled="editModal"
                                :options="gamelaOptions"
                                :value.sync="StowagePlanningImplementId"
                                :is-valid="hasError($v.StowagePlanningImplementId)"
                                v-model.trim="$v.StowagePlanningImplementId.$model"
                                :invalid-feedback="errorMessage($v.StowagePlanningImplementId)"
                                size="sm"
                            ></CSelect>
                        </div-->
                        <div class="form-group form-row mt-3">
                            <label class="col-form-label-sm text-right col-sm-12 col-lg-4 mb-0 required">{{OrdenOpenBin ? $t('label.nueva')+$t('label.Gamela') : $t('label.Gamela')}}</label>
                            <div class="col-sm-12  col-lg-8">
                                <v-select
                                    :disabled="!OrdenOpenBin&&editModal"
                                    id="v-select-small"
                                    :placeholder="$t('label.select')"
                                    :options="GamelaOption"
                                    :get-option-key="getOptionKey"
                                    :reduce="option => option.value" 
                                    v-model.trim="StowagePlanningImplementId"
                                    :class="computedSelectImplement ? 'select-driver--correct' : (verifyImplement ? 'select-driver--error' : '') "
                                />
                            </div>
                        </div>
                        
                    </CCol>
                    <CCol sm="12" lg="5">
                        <div class="mt-3">
                            <CSelect
                                :label="OrdenOpenYard ? $t('label.nuevo')+$t('label.yard') :  $t('label.yard')"
                                :horizontal="{
                                    label: 'col-sm-12  col-lg-4',
                                    input: 'col-sm-12  col-lg-8',
                                }"
                                add-label-classes="text-right"
                                :disabled="!OrdenOpenYard&&editModal"
                                :options="yardOptions"
                                :value.sync="YardId"
                                :is-valid="hasError($v.YardId)"
                                v-model.trim="$v.YardId.$model"
                                :invalid-feedback="errorMessage($v.YardId)"
                                @change="filtreYard"
                                size="sm"
                            ></CSelect>
                        </div>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="12" lg="7">
                        <!--div class="mt-3">
                            <CSelect
                                :label="$t('label.transport')"
                                :horizontal="{
                                    label: 'col-sm-12  col-lg-3',
                                    input: 'col-sm-12  col-lg-9',
                                }"
                                addLabelClasses="required text-right"
                                :options="transporteOptions"
                                :value.sync="StowagePlanningResourceId"
                                :is-valid="hasError($v.StowagePlanningResourceId)"
                                v-model.trim="$v.StowagePlanningResourceId.$model"
                                :invalid-feedback="errorMessage($v.StowagePlanningResourceId)"
                                size="sm"
                            ></CSelect>
                        </div-->
                        <div class="form-group form-row mt-3">
                            <label class="col-form-label-sm text-right col-sm-12 col-lg-4 mb-0 required">{{OrdenOpenVehicle ? $t('label.nuevo')+$t('label.transport') : $t('label.transport')}}</label>
                            <div class="col-sm-12  col-lg-8">
                                <v-select
                                    :disabled="!OrdenOpenVehicle&&editModal"
                                    :placeholder="$t('label.select')"
                                    :options="transporteOptions"
                                    :get-option-key="getOptionKey"
                                    :reduce="option => option.value" 
                                    label="label"
                                    v-model.trim="StowagePlanningResourceId"
                                    :value.sync="StowagePlanningResourceId"
                                    :class="computedSelectResource ? 'select-driver--correct' : (verifyResource ? 'select-driver--error' : '') "
                                    size="sm"
                                    id="v-select-small"
                                />
                            </div>
                        </div>
                    </CCol>
                    <CCol sm="12" lg="5">    
                        <div class="mt-3">
                            <CSelect
                                :label="'BL'"
                                :horizontal="{
                                    label: 'col-sm-12  col-lg-4',
                                    input: 'col-sm-12  col-lg-8',
                                }"
                                add-label-classes="text-right"
                                :options="optionsBillOfLadingYardList"
                                :value.sync="BillOfLadingYardId"
                                :is-valid="hasError($v.BillOfLadingYardId)"
                                v-model.trim="$v.BillOfLadingYardId.$model"
                                :invalid-feedback="errorMessage($v.BillOfLadingYardId)"
                                :disabled="YardId == null||YardId == ''||(!OrdenOpenYard&&editModal)"
                                size="sm"
                                @change="filterBL"
                            ></CSelect>
                        </div>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="12" lg="7">
                        <!--div class="mt-3">
                            <CSelect
                                :label="$t('label.vehicle')"
                                :horizontal="{
                                    label: 'col-sm-12  col-lg-3',
                                    input: 'col-sm-12  col-lg-9',
                                }"
                                addLabelClasses="required text-right"
                                :options="optionsVehicleList"
                                :value.sync="StowagePlanningVehicleId"
                                :is-valid="hasError($v.StowagePlanningVehicleId)"
                                v-model.trim="$v.StowagePlanningVehicleId.$model"
                                :invalid-feedback="errorMessage($v.StowagePlanningVehicleId)"
                                :disabled="StowagePlanningResourceId == null"
                                size="sm"
                            ></CSelect>
                        </div-->

                        <div class="form-group form-row mt-3">
                            <label class="col-form-label-sm text-right col-sm-12 col-lg-4 mb-0 required">{{OrdenOpenVehicle ? $t('label.nuevo')+$t('label.vehicle') : $t('label.vehicle')}}</label>
                            <div class="col-sm-12  col-lg-8">
                                <v-select
                                    :placeholder="$t('label.select')"
                                    :options="optionsVehicleList"
                                    :get-option-key="getOptionKey"
                                    :reduce="option => option.value" 
                                    label="label"
                                    v-model.trim="StowagePlanningVehicleId"
                                    :value.sync="StowagePlanningVehicleId"
                                    :class="computedSelectVehicle ? 'select-driver--correct' : (verifyVehicle ? 'select-driver--error' : '') "
                                    :disabled="StowagePlanningResourceId == null||(!OrdenOpenVehicle&&editModal)"
                                    size="sm"
                                    id="v-select-small"
                                />
                            </div>
                        </div>
                    </CCol> 
                    <CCol sm="12" lg="5">
                        <div class="mt-3">
                            <CInput
                                :label="$t('label.clientYard')"
                                :horizontal="{
                                    label: 'col-sm-12  col-lg-4',
                                    input: 'col-sm-12  col-lg-8',
                                }"
                                add-label-classes="text-right"
                                :placeholder="$t('label.clientYard')"
                                :value.sync="ClientName"
                                :is-valid="hasError($v.ClientTpId)"
                                v-model.trim="$v.ClientName.$model"
                                :invalid-feedback="errorMessage($v.ClientTpId)"
                                :disabled="true"
                                size="sm"
                            ></CInput>
                        </div>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol sm="12" lg="7">
                        <div class="form-group form-row mt-3">
                            <label class="col-form-label-sm text-right col-sm-12  col-lg-4 mb-0">{{OrdenOpenVehicle ? $t('label.nuevo')+$t('label.driver') : $t('label.driver')}}</label>
                            <div class="col-sm-12  col-lg-8">
                                <v-select
                                    :placeholder="$t('label.select')"
                                    :options="optionsDriversList"
                                    :get-option-key="getOptionKey"
                                    :reduce="option => option.value" 
                                    label="label"
                                    v-model.trim="StowagePlanningDriverId"
                                    :value.sync="StowagePlanningDriverId"
                                    :class="computedSelectDriver ? 'select-driver--correct' : (verifyDriver ? 'select-driver--correct' : '') "
                                    :disabled="StowagePlanningResourceId == null||(!OrdenOpenVehicle&&editModal)"
                                    size="sm"
                                    id="v-select-small"
                                />
                            </div>
                        </div>
                    </CCol> 
                    <CCol sm="12" lg="5" v-if="editModal">
                        <div class="mt-3">
                            <CSelect
                                :label="$t('label.status')"
                                :horizontal="{
                                    label: 'col-sm-12  col-lg-4',
                                    input: 'col-sm-12  col-lg-8',
                                }"
                                :disabled="originStatus==0"
                                add-label-classes="text-right"
                                v-uppercase
                                :options="optionsStatus"
                                :invalid-feedback="errorMessage($v.Status)"
                                :is-valid="statusSelectColor()"
                                v-model.trim="$v.Status.$model"
                                :value.sync="Status"     
                                size="sm"
                            />
                        </div>
                    </CCol>
                </CRow>
                <CCollapse v-if="editModal&&ShowCollapse" :show="true" class="mt-2">
                    <CCard>
                        <CCardHeader class="text-center dark text-white">
                            <CRow>
                                <CCol sm="12" class="d-flex ">{{$t('label.OrderAffectedList')}}</CCol>
                            </CRow>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol sm="12">
                                    <dataTableExtended
                                        class="align-center-row-datatable"
                                        size="lg"
                                        :fields="fields"
                                        :items="computedListOrderOpen"
                                        :items-per-page="5"
                                        column-filter
                                        pagination
                                        :table-filter="tableText.tableFilterText"
                                        :items-per-page-select="tableText.itemsPerPageText"
                                        :noItemsView="tableText.noItemsViewText"
                                        sorter
                                    >
                                        <template #OrderCode="{item}">
                                            <td class="text-center">
                                                <CBadge color="success">
                                                    {{ item.OrderCode }}
                                                </CBadge>
                                            </td>
                                        </template>
                                        <template #OrderStatusDs="{item}">
                                            <td class="text-center">
                                                <CBadge color="success">
                                                    {{ item.OrderStatusDs }}
                                                </CBadge>
                                            </td>
                                        </template>
                                    </dataTableExtended>
                                </CCol>
                                <CCol class="col-sm-12">
                                    <div>
                                        <CInputCheckbox
                                            :label="Titlechecked(OrderOpenJson)"
                                            custom
                                            :checked.sync="ValidationOrder"
                                            class="checkbook-filter"
                                        />
                                    </div>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCollapse>      
            </div>
            <template #footer>
                <CButton
                    v-if="originStatus!=0"
                    outline
                    color="add"
                    :disabled="ShowCollapse?!ValidationOrder:isSubmit||originStatus==0"
                    @click="editModal?statusConfirmation(originStatus, Status, submit):submit()" 
                >
                    <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
                </CButton>
                <CButton
                    color="wipe"
                    class="d-flex align-items-center"
                    :disabled="isSubmit"
                    @click.stop="toggle(false)"
                >
                    <CIcon name="x"/><span class="ml-1">{{ originStatus!=0 ? $t('button.cancel') : $t('button.exit') }}</span>
                </CButton>
            </template>
        </CModalExtended>
    </main>
</template>

<script>
    import UpperCase from '@/_validations/uppercase-directive';
    import GeneralMixin from '@/_mixins/general';
    import ModalMixin from '@/_mixins/modal';
    import { asignacion } from '@/_validations/planificacion-estiba/logistica-empresa';
    import { mapState } from 'vuex';
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';

    function data() {
        return {
            flag: false,
            modalActive: false,
            loadingOverlay: false,
            loadingTable: false,
            optionsStatus: [
                { label: this.$t('label.ACTIVO'), value: 1 },
                { label: this.$t('label.INACTIVO'), value: 0 },
            ],
            selected:false,
            isSubmit: false, 
            verifyDriver: false,
            verifyVehicle: false,
            verifyImplement: false,
            verifyResource: false,
            OrdenOpenBin: false,
            OrdenOpenVehicle: false,
            OrdenOpenYard: false,
            ValidationOrder: false,
            ShowCollapse: false,

            OriginStowagePlanningResourceId: null,
            originStowagePlanningVehicleId: null,
            OriginStowagePlanningImplementId: null,
            OriginDriverJson: [],
            OriginVehicleJson: [],
            OrderOpenJson: [],

            //selects
            gamelaOptions:[],
            conductorOptions:[],
            transporteOptions:[],
            VehicleBlOptions:[],
            yardOptions:[],
            ClientTpOptions:[],
            //formulario
            StowagePlanningImplementId:null,
            StowagePlanningDriverId:null,
            StowagePlanningVehicleId:null,
            StowagePlanningResourceId:null,
            ClientName: null,
            YardId:null,
            ClientTpId:null,
            BillOfLadingYardId:null,
            originStatus: 1,
            Status: 1, 
        }
    }

    //methods
    async function listarGamela () {
        this.loadingOverlay = true;
        this.companyOptions = [];

        let listado = Array;
        await this.$http.get("StowagePlanningImplementWithOutVehicle-list", { StowagePlanningId:this.StowagePlanningId,StowagePlanningImplementVehicleId:this.StowagePlanningImplementVehicleId })
        .then(response => {
            listado = [...response.data.data];
            this.gamelaOptions = listado;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });

        if(this.selected){
            await this.listarChofer(true);
            await this.listarPatios();
        }else{
            this.selected = false;
        }
    }

    async function listarChofer (flag) {
        this.loadingOverlay = true;
        this.companyOptions = [];
        let Params =this.editModal?{StowagePlanningId:this.StowagePlanningId,StowagePlanningImplementVehicleId:this.StowagePlanningImplementVehicleId}
            : {StowagePlanningId:this.StowagePlanningId,StowagePlanningImplementVehicleId:''};
        let listado = Array;
        await this.$http.get("StowagePlanningCarrierWithOutAssociated-list", Params)
        .then(response => {
            listado = [...response.data.data];
            this.transporteOptions = listado.map(listado => Object.assign({}, this.transporteOptions, {
                value: listado.StowagePlanningResourceId,
                label: listado.Alias,
                DriverJson: listado.DriverJson,
                VehicleJson: listado.VehicleJson,
            }));
           /* if (flag==false) {
                const x  = this.transporteOptions.map(function(e){
                    return e.value;
                }).indexOf(this.OriginStowagePlanningResourceId);
                if(x !== -1){
                    this.transporteOptions[x].DriverJson = this.OriginDriverJson;
                    this.transporteOptions[x].VehicleJson = this.OriginVehicleJson;
                }
            }*/

            this.transporteOptions.unshift({
                value: null,
                label: this.$t('label.select'),
                DriverJson: [],
                VehicleJson: []
            });
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function listarPatios () {
        this.loadingOverlay = true;
        this.companyOptions = [];

        let listado = Array;
        await this.$http.get("StowagePlanningMetalScrapYard-list", { CompanyBranchId: this.getBranchId })
        .then(response => {
            listado = [...response.data.data];
            this.yardOptions = listado.map(listado => Object.assign({}, this.yardOptions, {
                value: listado.YardId,
                label: listado.YardName,
                BlYardDetailJson: listado.BlYardDetailJson
            }));

            this.yardOptions.unshift({
                value: "",
                label: this.$t('label.select'),
                BlYardDetailJson: []
            });
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    function selectedStatus(event) {
        this.Status = event.target.value;
    }

    function filtreYard(event) {
        this.YardId = event.target.value;
        if (this.YardId==this.objectAsignacion.YardId) {
            this.ClientTpId = this.objectAsignacion.YardClientTpId;
            this.ClientName =  this.objectAsignacion.YardClientName;
            this.BillOfLadingYardId = this.objectAsignacion.BillOfLadingYardId;
        } else {
            this.BillOfLadingYardId = '';
            this.ClientName =  '';
            this.ClientTpId = '';
        }
    }

    function filterBL(event) {
        this.BillOfLadingYardId = event.target.value;
        let x  = this.yardOptions.map(function(e){
            return e.value;
        }).indexOf(this.YardId);
        if(x !== -1){
            let ClientYard = this.yardOptions[x].BlYardDetailJson.find(item => item.BillOfLadingYardId == this.BillOfLadingYardId);
            if (ClientYard) {
                this.ClientTpId = ClientYard.ClientTpId;
                this.ClientName =  ClientYard.ClientName;
            }else{
                this.ClientTpId = '';
                this.ClientName =  '';
            }
        }else{
            this.ClientTpId = '';
            this.ClientName =  '';
        }
    }

    function resetForm(event) {
        this.StowagePlanningImplementId = null;
        this.StowagePlanningDriverId = null;
        this.StowagePlanningVehicleId = null;
        this.StowagePlanningResourceId = null;
        this.OriginStowagePlanningResourceId = null;
        this.originStowagePlanningVehicleId = null;
        this.OriginStowagePlanningImplementId = null;
        this.YardId = null;
        this.ClientTpId = null;
        this.BillOfLadingYardId = null;
        this.ClientName = null;
        this.Status = 1;
        this.originStatus = 1;
        this.verifyDriver = false;
        this.verifyVehicle = false;
        this.verifyImplement = false;
        this.verifyResource = false;
        this.flag = false;
        this.OrdenOpenBin = false,
        this.OrdenOpenVehicle = false,
        this.OrdenOpenYard = false,
        this.ValidationOrder = false;
        this.ShowCollapse = false;
        this.OrderOpenJson = [];

        /*this.openDate = false;
        this.collapse = false;*/
        this.$v.$reset();
        this.$emit('updated-modal', event);
    }

    async function submit(){
        //this.Driver= this.$t('label.IdNumber')+' '+this.DriverCi+' - '+this.DriverName;
        try {
            this.loadingOverlay = true;
            this.isSubmit = true;
            let ChangeEdit = false;
            if (this.editModal) {
                ChangeEdit = this.YardId==this.objectAsignacion.YardId&&this.StowagePlanningVehicleId==this.originStowagePlanningVehicleId
                    &&this.StowagePlanningImplementId==this.OriginStowagePlanningImplementId&&this.Status==this.originStatus&&
                    this.BillOfLadingYardId==this.objectAsignacion.BillOfLadingYardId&&this.StowagePlanningDriverId==this.objectAsignacion.StowagePlanningDriverId?
                    true : false;
            }

            this.$v.$touch();
            if (this.$v.$error||this.originStatus==0||ChangeEdit) {
                //this.loadingOverlay = false;
                this.verifyDriver = true;
                this.verifyVehicle = true;
                this.verifyImplement = true;
                this.verifyResource = true;
                if (ChangeEdit) {
                    throw this.$t('label.NoChangesToModify');
                }
                throw this.$t('label.errorsPleaseCheck');
            }

            const StowagePlanningImplementVehicleJson = {
                StowagePlanningImplementId: (this.StowagePlanningImplementId !== null) ? this.StowagePlanningImplementId : '',
                StowagePlanningDriverId: (this.StowagePlanningDriverId !== null) ? this.StowagePlanningDriverId : '',
                StowagePlanningVehicleId: (this.StowagePlanningVehicleId !== null) ? this.StowagePlanningVehicleId : '',
                BillOfLadingYardId: this.BillOfLadingYardId,
                YardId: this.YardId,
                ClientTpId: this.ClientTpId,
                Status: 1
            };

            if(this.editModal){
                StowagePlanningImplementVehicleJson.Status= this.Status;
                StowagePlanningImplementVehicleJson.StowagePlanningImplementVehicleId= this.StowagePlanningImplementVehicleId;
            };

            let method = this.editModal ? 'PUT':'POST';
            let ruta = this.editModal ? 'StowagePlanningImplementVehicle-update':'StowagePlanningImplementVehicle-insert';

            await this.$http
            .ejecutar (method, ruta, StowagePlanningImplementVehicleJson, { root: 'StowagePlanningImplementVehicleJson' })
            .then((response) => {
                if (response.status === (200 || 201)) {
                    const messageSuccess = response.data.data[0].Response;
                    this.$emit('refresh-data-table');
                    this.isSubmit = false;
                    this.toggle(false);
                    this.loadingOverlay = false;
                    this.notifySuccess({ text: messageSuccess });
                }
            })
            .catch((e) => {
                this.loadingOverlay = false;
                this.notifyError({ text: e });
            }).then(() => {
                this.isSubmit = false;
            });
        } catch (e) {
            this.loadingOverlay = false;
            this.isSubmit = false;
            this.notifyError({ text: e });
        }
    }

    function  getOptionKey (option) {
        if (typeof option === 'object' && option.value) {
            return option.value;
        } else {
            return '';
        }
    }
    //computeds
    function fields(){
        return [
            { key: 'ImplementAlias', label: this.$t('label.Gamela'),_classes:"center-cell"},
            { key: 'OrderCode', label: this.$t('label.order'),_classes:"center-cell", _style:'min-width: 150px;'},
            { key: 'CarrierAlias', label: this.$t('label.transport'),_classes:"center-cell"},
            { key: 'DriverFull', label: this.$t('label.driver'),_classes:"center-cell"},
            { key: 'LicensePlate', label: this.$t('label.vehicle'),_classes:"center-cell"},
            { key: 'YardName', label: this.$t('label.yard'),_classes:"center-cell"},
            { key: 'D_Emission', label: this.$t('label.D_emission'),_classes:"center-cell", _style:'min-width: 120px;'},
            { key: 'TareTon', label: this.$t('label.Taraweight')+' '+this.$t('label.metricTonnageAcronym'),_classes:"center-cell", _style:'min-width: 120px;'},
            { key: 'FullTon', label: this.$t('label.FullWeight')+' '+this.$t('label.metricTonnageAcronym'),_classes:"center-cell", _style:'min-width: 120px;'},
            { key: 'NetTon', label: this.$t('label.netWeight')+' '+this.$t('label.metricTonnageAcronym'),_classes:"center-cell", _style:'min-width: 120px;'},
            { key: 'TransaLogin', label: this.$t('label.user'),_classes:"center-cell",sorter: true },
            { key: 'FormatedDate', label:this.$t('label.date'), _classes:'center-cell',sorter: true},
            { key: 'Observation', label:this.$t('label.observation'), _classes:"center-cell",sorter: true },
            { key: "OrderStatusDs", label: this.$t("label.status"), _classes: "text-center" },
        ];
    }

    function Titlechecked(Order) {
        let min = false;
        Order.map(item => {
            if(item.FullWeight > 0 && item.NetWeight > 0){
                min = true;
            }
        })
        if (min) {
            return this.$t('label.OverrideOrderAndWeighingEvents');
        } else {
            return this.$t('label.OverrideOrder');
        }
    }

    function computedListOrderOpen() {
        let _lang = this.$i18n.locale;
        if (this.OrderOpenJson.length > 0) {
            return this.OrderOpenJson.map((item) => {
                return {
                    ...item,
                    _classes: item.OrderStatusDsEn == 'OPEN' ? 'Correct-Row' : item.OrderStatusDsEn == 'CLOSED' ? 'Alert-Row' : 'Error-Row',
                    DriverFull: item.DriverCi&&item.DriverName ? '('+item.DriverCi+') '+item.DriverName : '',
                    D_Emission: item.TransactionDate?DateFormater.formatOnlyDateWithSlash(item.TransactionDate):'N/A',
                    TareTon: NumberFormater.formatNumber(item.TareWeight, 2),
                    FullTon: NumberFormater.formatNumber(item.FullWeight, 2),
                    NetTon: NumberFormater.formatNumber(item.NetWeight, 2),
                    FormatedDate: item.TransaRegDate?DateFormater.formatOnlyDateWithSlash(item.TransaRegDate): 'N/A',
                    OrderStatusDs: _lang=='en'?item.OrderStatusDsEn:item.OrderStatusDsEs,
                };
            });
        }else{
            return [];
        }
    }

    function GamelaOption(){
        if(this.gamelaOptions.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select')
            }];
        }else{
            const chart = [{
                value: '', 
                label: this.$t('label.select')
            }];
            this.gamelaOptions.map(function(listado){
                chart.push({
                    value: listado.StowagePlanningImplementId,
                    label: listado.ImplementAlias,
                });
            });
            return chart;
        }
    }
    function getBranchId() {
        return this.branch ? this.branch.CompanyBranchId : "";
    }
    function optionsDriversList(){
        if(this.transporteOptions.length === 0 || this.StowagePlanningResourceId === null){
            return [{
                value: '', 
                label: this.$t('label.select')
            }];
        }else{
            const chart = [{
                value: '', 
                label: this.$t('label.select')
            }];
            const x  = this.transporteOptions.map(function(e){
                return e.value;
            }).indexOf(this.StowagePlanningResourceId);
            if(x !== -1){
                this.transporteOptions[x].DriverJson.map(function(e){
                    chart.push({
                        value: e.StowagePlanningDriverId, 
                        label: '('+e.DriverCi+') '+e.DriverName,
                    })
                })
            }
            return chart;
        }
    }

    function optionsVehicleList(){
        if(this.transporteOptions.length === 0 || this.StowagePlanningResourceId === null){
            return [{
                value: '', 
                label: this.$t('label.select')
            }];
        }else{
            const chart = [{
                value: '', 
                label: this.$t('label.select')
            }];
            const x  = this.transporteOptions.map(function(e){
                return e.value;
            }).indexOf(this.StowagePlanningResourceId);
            if(x !== -1){
                this.transporteOptions[x].VehicleJson.map(function(e){
                    chart.push({
                        value: e.StowagePlanningVehicleId, 
                        label: e.LicensePlate,
                    })
                })
            }
            return chart;
        }
    }

    /*function optionsClienteList(){
        if(this.yardOptions.length === 0 || this.YardId === null){
            return [{
                value: '', 
                label: this.$t('label.select')
            }];
        }else{
            const chart = [{
                value: '', 
                label: this.$t('label.select')
            }];
            const x  = this.yardOptions.map(function(e){
                return e.value;
            }).indexOf(this.YardId);
            if(x !== -1){
                this.yardOptions[x].BlYardDetailJson.map(function(e){
                    chart.push({
                        value: e.ClientTpId, 
                        label: e.ClientName,
                    })
                })
            }
            return chart;
        }
    }*/

    function optionsBillOfLadingYardList(){
        if(this.yardOptions.length === 0 || this.YardId === null){
            return [{
                value: '', 
                label: this.$t('label.select')
            }];
        }else{
            const chart = [{
                value: '', 
                label: this.$t('label.select')
            }];
            const x  = this.yardOptions.map(function(e){
                return e.value;
            }).indexOf(this.YardId);
            if(x !== -1){
                this.yardOptions[x].BlYardDetailJson.map(function(e){
                    chart.push({
                        value: e.BillOfLadingYardId, 
                        label: e.NroBl,
                    })
                })
            }
            return chart;
        }
    }
    function computedSelectDriver() {
        if (this.StowagePlanningDriverId === null||this.StowagePlanningDriverId === '') {
        return false;
        } else {
        return true;
        }
    }
    function computedSelectVehicle() {
        if (this.StowagePlanningVehicleId === '' || this.StowagePlanningVehicleId === null) {
        return false;
        } else {
        return true;
        }
    }
    function computedSelectImplement() {
        if (this.StowagePlanningImplementId === '' || this.StowagePlanningImplementId === null) {
        return false;
        } else {
        return true;
        }
    }
    function computedSelectResource() {
        if (this.StowagePlanningResourceId === '' || this.StowagePlanningResourceId === null) {
        return false;
        } else {
        return true;
        }
    }
    function computedColumnWidth(){
        if(this.editModal){
            return '7';
        }else{
            return '7';
        }
    }
    export default {
        name:'modal-asignacion',
        data,
        props: {
            title: String,
            modal: null,
            editModal: Boolean,
            objectAsignacion: Object,
            StowagePlanningImplementVehicleId: String,
        },
        mixins: [GeneralMixin, ModalMixin],
        directives: UpperCase,
        mounted(){
            this.toggle(false);
        },
        methods:{
            filterBL,
            Titlechecked,
            filtreYard,
            listarGamela,
            listarChofer,
            listarPatios,
            selectedStatus,
            resetForm,
            submit,
            getOptionKey,
        },
       // validations: asignacion,
        validations(){ 
            return asignacion(this.YardId) 
        },
        computed:{
            computedListOrderOpen,
            fields,
            GamelaOption,
            getBranchId,
            optionsDriversList,
            optionsVehicleList,
            //optionsClienteList,
            optionsBillOfLadingYardList,
            computedSelectDriver,
            computedSelectVehicle,
            computedSelectImplement,
            computedSelectResource,
            computedColumnWidth,
            nameGamela(){
                if (Object.keys(this.objectAsignacion).length !== 0) {
                    return this.objectAsignacion.ImplementAlias;
                }
                return '';
            },
            ...mapState({
                branch: (state) => state.auth.branch,
                StowagePlanningId: state => state.planificacionestiba.planificacionId,
                TpVesselId: state => state.planificacionestiba.TpVesselId,
                user: state => state.auth.user,
            })
        },
        watch: {
            modal: async function(val) {
                this.modalActive = val;
                if(val){
                    this.selected = true;
                    await this.listarGamela();
                }
                if(this.editModal && Object.keys(this.objectAsignacion).length !== 0){
                    this.OriginStowagePlanningImplementId = this.objectAsignacion.StowagePlanningImplementId ? this.objectAsignacion.StowagePlanningImplementId : '';
                    this.StowagePlanningImplementId = this.objectAsignacion.StowagePlanningImplementId ? this.objectAsignacion.StowagePlanningImplementId : '';

                    this.OriginStowagePlanningResourceId = this.objectAsignacion.StowagePlanningResourceId ? this.objectAsignacion.StowagePlanningResourceId : '';
                    this.StowagePlanningResourceId = this.objectAsignacion.StowagePlanningResourceId ? this.objectAsignacion.StowagePlanningResourceId : '';

                    this.StowagePlanningDriverId = this.objectAsignacion.StowagePlanningDriverId ? this.objectAsignacion.StowagePlanningDriverId : '';

                    this.originStowagePlanningVehicleId =  this.objectAsignacion.StowagePlanningVehicleId ? this.objectAsignacion.StowagePlanningVehicleId : '';
                    this.StowagePlanningVehicleId = this.objectAsignacion.StowagePlanningVehicleId ? this.objectAsignacion.StowagePlanningVehicleId : '';

                    this.YardId = this.objectAsignacion.YardId;
                    this.ClientTpId = this.objectAsignacion.YardClientTpId;
                    this.ClientName =  this.objectAsignacion.YardClientName;
                    this.BillOfLadingYardId = this.objectAsignacion.BillOfLadingYardId;
                    this.originStatus = this.objectAsignacion.Status === 'ACTIVO' ? 1 : 0;
                    this.Status = this.objectAsignacion.Status === 'ACTIVO' ? 1 : 0;

                    this.verifyDriver = false;
                    this.verifyVehicle = false;
                    this.verifyImplement = false;
                    this.verifyResource = false;
                    this.$v.$touch();
                }
            },
            StowagePlanningResourceId: async function(val) {
                if (!this.editModal||this.flag) {
                    if (val==this.OriginStowagePlanningResourceId) {
                        this.StowagePlanningDriverId = this.objectAsignacion.StowagePlanningDriverId ? this.objectAsignacion.StowagePlanningDriverId : '';
                        this.StowagePlanningVehicleId = this.objectAsignacion.StowagePlanningVehicleId ? this.objectAsignacion.StowagePlanningVehicleId : '';
                    }else{
                        this.StowagePlanningVehicleId = '';
                        this.StowagePlanningDriverId = '';
                    }
                }else {
                    this.flag = true;
                    /*let x  = this.transporteOptions.map(function(e){
                        return e.value;
                    }).indexOf(this.OriginStowagePlanningResourceId);
                    if(x !== -1){
                        this.OriginDriverJson = this.transporteOptions[x].DriverJson;
                        this.OriginVehicleJson = this.transporteOptions[x].VehicleJson;
                    }
                    await this.listarChofer(false);*/
                }
            },
            StowagePlanningImplementId: function (val) {
                if (this.editModal) {
                    let Json = []
                    let x  = this.transporteOptions.map(function(e){
                         return e.value;
                    }).indexOf(this.StowagePlanningResourceId);
                    let OriginVehicle = this.transporteOptions[x].VehicleJson.find( item => item.StowagePlanningVehicleId === this.StowagePlanningVehicleId );
                    OriginVehicle.OrdersJson&&OriginVehicle.OrdersJson.length!=0 ? Json.push(OriginVehicle.OrdersJson[0])  : Json = [];
                    if (this.OriginStowagePlanningImplementId != val && val) {
                        let Bin = this.gamelaOptions.find( item => item.StowagePlanningImplementId === val )
                        if (Bin.OrdersJson&&Bin.OrdersJson.length>0) {
                            Bin.OrdersJson.map(item => {
                                Json.push(item);
                            })
                            this.OrderOpenJson = Json;
                            this.ShowCollapse = true;
                        }else{
                            if (OriginVehicle.OrdersJson&&OriginVehicle.OrdersJson.length!=0) {
                                this.ShowCollapse = true;
                                this.OrderOpenJson = OriginVehicle.OrdersJson;
                            }else{
                                this.ShowCollapse = false;
                                this.OrderOpenJson = [];
                            }
                        }
                    }else{
                        this.ShowCollapse = false;
                        this.OrderOpenJson = [];
                    }
                }
                
            },
            StowagePlanningVehicleId: function (val) {
                if (this.editModal) {
                    let Json = []
                    let OriginBin = this.gamelaOptions.find( item => item.StowagePlanningImplementId === this.StowagePlanningImplementId )
                    OriginBin.OrdersJson&&OriginBin.OrdersJson.length!=0 ? Json.push(OriginBin.OrdersJson[0])  : Json = [];
                    if (this.originStowagePlanningVehicleId != val && val) {
                        let x  = this.transporteOptions.map(function(e){
                            return e.value;
                        }).indexOf(this.StowagePlanningResourceId);
                        if(x !== -1){
                            let Vehicle = this.transporteOptions[x].VehicleJson.find( item => item.StowagePlanningVehicleId === val );
                            if (Vehicle.OrdersJson&&Vehicle.OrdersJson.length>0) {
                                Vehicle.OrdersJson.map(item => {
                                    Json.push(item);
                                })
                                this.OrderOpenJson = Json;
                                this.ShowCollapse = true;
                            }else{
                                if (OriginBin.OrdersJson&&OriginBin.OrdersJson.length!=0) {
                                    this.ShowCollapse = true;
                                    this.OrderOpenJson = OriginBin.OrdersJson;
                                }else{
                                    this.ShowCollapse = false;
                                    this.OrderOpenJson = [];
                                }
                            }
                        }
                    }else{
                        this.ShowCollapse = false;
                        this.OrderOpenJson = [];
                    }
                }
            },
            OrdenOpenBin: function (val) {
                if (val) {
                    this.ShowCollapse = false
                    this.OrdenOpenVehicle=false;
                }else{
                    this.ShowCollapse = false;
                    this.OrderOpenJson = [];
                    this.StowagePlanningImplementId = this.objectAsignacion.StowagePlanningImplementId ? this.objectAsignacion.StowagePlanningImplementId : '';
                }
            },
            OrdenOpenVehicle: function (val) {
                if (val) {
                    this.ShowCollapse = false
                    this.OrdenOpenBin=false;
                }else{
                    this.ShowCollapse = false
                    this.OrderOpenJson = [];
                    this.StowagePlanningResourceId = this.objectAsignacion.StowagePlanningResourceId ? this.objectAsignacion.StowagePlanningResourceId : '';
                    this.StowagePlanningDriverId = this.objectAsignacion.StowagePlanningDriverId ? this.objectAsignacion.StowagePlanningDriverId : '';
                    this.StowagePlanningVehicleId = this.objectAsignacion.StowagePlanningVehicleId ? this.objectAsignacion.StowagePlanningVehicleId : '';
                }
            },
            OrdenOpenYard: function (val) {
                if (!val) {
                    this.YardId = this.objectAsignacion.YardId;
                    this.ClientName =  this.objectAsignacion.YardClientName;
                    this.ClientTpId = this.objectAsignacion.YardClientTpId;
                    this.BillOfLadingYardId = this.objectAsignacion.BillOfLadingYardId;
                }
            },
        }
    }
</script>

<style lang="scss">
.adjuts-driver {
    padding-left: 2px !important;
}
.select-driver--correct {
    .vs__dropdown-toggle {
        border-color: #2eb85c !important;
    }
}
.select-driver--error {
    .vs__dropdown-toggle {
        border-color: #e55353 !important;
    }
}
</style>