<template>
    <main>
        <CModalExtended
            :title="title"
            color="dark"
            :show.sync="modalActive"
            class="modal-content-asignacion"
            :closeOnBackdrop="false"
            size="xl"
        >
            <loading-overlay
                :active="loadingOverlay"
                :is-full-page="true"
                loader="bars"
            />
            <CRow class="mt-2">
                <CCol sm="12" class="mt-3 d-flex align-items-center justify-content-end">
                    <CButton
                        color="excel"
                        @click="onBtnExport(true)"
                    >
                        <CIcon name="cil-file-excel"/>&nbsp; XLSX
                    </CButton>
                </CCol>
                
                <CCol sm="12" class="mt-3">
                    <dataTableExtended
                        class="align-center-row-datatable"
                        hover
                        sorter
                        small
                        column-filter
                        :table-filter="tableTextHelpers.tableFilterText"
                        :items-per-page-select="tableTextHelpers.itemsPerPageText"
                        :items="dateFilteredItems"
                        :fields="fields"
                        :noItemsView="tableTextHelpers.noItemsViewText"
                        :items-per-page="5"
                        pagination
                        @filtered-items-change="getFilteredList"
                    >
                        <template #RowNumber="{index}">
                            <td class="center-cell text-center">
                                {{index+1}}
                            </td>
                        </template>
                        <template #OrderStatusDsText="{ item }">
                            <td class="center-cell text-center" >
                                <span class="text-color-negrita status-color" :style="StatusColor(item)">
                                    {{ item.OrderStatusDsText }}
                                </span>
                            </td>
                        </template>
                        <template v-if="typeArray === 2" #show_details="{item}">
                            <td class="text-center" style="padding:0px;">
                                <CButton
                                    v-if="item.OrdersJson.length !== 0"
                                    block
                                    color="watch"
                                    size="sm"
                                    @click="toggleLevel(item)"
                                >
                                    <CIcon name="eye" />
                                </CButton>
                            </td>
                        </template>
                        <template v-if="typeArray === 2" #details="{item}">
                            <CCollapse
                                :show="Boolean(item._toggled)"
                                :duration="collapseDuration"
                            >
                                <dataTableExtended
                                    class="align-center-row-datatable table-lv-2 table-responsive"
                                    :items="formattedNivel2"
                                    :fields="fieldslv1and2"
                                    :items-per-page="5"
                                    sorter
                                    pagination
                                    :table-filter="tableTextHelpers.tableFilterText"
                                    :items-per-page-select="tableTextHelpers.itemsPerPageText"
                                    :noItemsView="tableTextHelpers.noItemsViewText"
                                >
                                    <template #OrderStatusDsText="{ item }">
                                        <td class="center-cell text-center" >
                                            <span class="text-color-negrita status-color"  :style="StatusColor(item)">
                                                {{ item.OrderStatusDsText }}
                                            </span>
                                        </td>
                                    </template>
                                </dataTableExtended>
                            </CCollapse>
                        </template>
                    </dataTableExtended>
                </CCol>
            </CRow>
            <template #footer>
                <CButton
                    color="wipe"
                    class="d-flex align-items-center"
                    :disabled="isSubmit"
                    @click.stop="toggle(false)"
                >
                    <CIcon name="x"/><span class="ml-1">{{$t('button.exit')}}</span>
                </CButton>
            </template>
        </CModalExtended>
    </main>
</template>

<script>
    import GeneralMixin from '@/_mixins/general';
    import ModalMixin from '@/_mixins/modal';
    import { DateFormater } from '@/_helpers/funciones';
    import { mapState } from 'vuex';
    import moment from 'moment';
    import { thisExpression } from '@babel/types';
    import Reportes from '@/_mixins/reportes';
    import {saveAs} from "file-saver";
    import Excel from "exceljs/dist/es5/exceljs.browser";
    function data() {
        return {
            modalActive: false,
            loadingOverlay: false,
            itemsNivelII:[],
            collapseDuration:0,
            filteredList: [],
        }
    }
    //methods
    function StatusColor(item) {
        if(item) return { color: item.ColorStatus };
        return {}
    }
    async function onBtnExport(valor){
        if(this.filteredList.length !== 0){
            let locale = this.$i18n.locale;
            this.loadingOverlay = true;
            let list = [];
            let columnsDefs = [];

            for (let index = 0; index < (this.typeArray === 1 ? this.fields.length : this.fields.length-1); index++) {
                const elementL = this.fields[index];
                columnsDefs.push(elementL.label);
            }
            
            await this.filteredList.map(async (item) => {
                let myObj = new Object();
                let length = this.typeArray === 1 ? this.fields.length : this.fields.length-1;
                for (let index = 0; index < length; index++) {
                    const element = this.fields[index];
                    myObj[element.label] = item[element.key];
                }
                if(this.typeArray === 2){
                    if(item['OrdersJson'] !== null ){
                        if(item['OrdersJson'].length > 0){
                            let columnsDefsHijo = [];
                            let listHijo = [];
                            /*for (let index = 0; index < this.fieldslv1and2.length; index++) {
                                const elementHijo = this.fieldslv1and2[index];
                                columnsDefsHijo.push(elementHijo.label);
                                //myObjHijo[elementHijo.label] = item[elementHijo.key];
                            }*/
                            let OrdersJson = item['OrdersJson'].map((item2,Index) => Object.assign({}, item2, {
                                ...item2,
                                RowNumber: Index+1,
                                YardName: item2.YardName == null ? "N/A" : item2.YardName,
                                DriverFull: item2.DriverCi&&item2.DriverName ? '('+item2.DriverCi+') '+item2.DriverName : '',
                                emisionDate: item2.TransactionDate ? DateFormater.formatOnlyDateWithSlash(item2.TransactionDate) : 'N/A',
                                finalDate: item2.FinishDate ? DateFormater.formatOnlyDateWithSlash(item2.FinishDate) : 'N/A',
                                UltimoUsuario: item2.TransaLogin,
                                FormatedDate: item2.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item2.TransaRegDate) : 'N/A',
                                Status: item2.Status ? item2.Status : 'N/A',
                                OrderStatusDsText: locale == 'es' ? item2.OrderStatusDsEs : item2.OrderStatusDsEn,
                            }));

                            await OrdersJson.map((itemHijo) => {
                                let myObjHijo = new Object();
                                for (let index = 0; index < this.fieldslv1and2.length; index++) {
                                    const elementHijo = this.fieldslv1and2[index];
                                    myObjHijo[elementHijo.label] = itemHijo[elementHijo.key];
                                }
                                listHijo.push(myObjHijo);
                            })
                            myObj['hijo'] = listHijo;
                        }
                    }
                }
                list.push(myObj);
            });
            if(list.length !== 0){
                let title = this.typeArray==1 ? this.$t('label.HistoryOrders')+'-'+this.nameGamela : this.$t('label.HistoryAssociation')+'-'+this.nameGamela;
                await this.getExcelArray2(list,title,valor,columnsDefs);
            }else{
                this.$notify({  
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.noRecordsAvailbleReport'),
                    type: "error" 
                });
            }
            this.loadingOverlay = false;
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
    }
    async function getExcelArray2(rowData, titulo, excel, columnsDefs, companyName, vesselInfo = true, nombreLibro) {
        const workbook = new Excel.Workbook();
        let nombreOpcional = nombreLibro ? nombreLibro : titulo;
        const worksheet = workbook.addWorksheet(nombreOpcional);
        const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

        let y = 0;
        let letra = 12;
        let keyColumns = [];
        let bandera = true;

        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera = false;

        //if (isUndefined(un)) un = 'TON';
        if (excel) {
            if(vesselInfo){
                if(companyName === undefined){
                    const Index = this.myDataItinirary.map(function(e) {
                        return e.ItineraryId; 
                    }).indexOf(this.ItineraryId);
                    
                    if(Index > -1){      
                        this.dataBuque.DatePlanning = this.myDataItinirary[Index].VesselName+" "+this.myDataItinirary[Index].Imo+", "+this.myDataItinirary[Index].CountryName+", ETA: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Eta)+", ETD: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Etd)+" - "+this.$t('label.voyage')+": "+this.myDataItinirary[Index].Voyage;
                        this.dataBuque.CountryName = this.myDataItinirary[Index].CountryName;
                    } else {
                        let itinerarySelected = this.itinerarySelected;
                        this.dataBuque.DatePlanning = `${itinerarySelected.VesselName}, - ${this.$t('label.voyage')}: ${itinerarySelected.Voyage}, ${itinerarySelected.LabelArrival} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Arrival)} - ${itinerarySelected.LabelDeparture} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Departure)}`;
                        this.dataBuque.CountryName = this.itinerarySelected.CountryName;
                        this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
                    }
                }
            }
            
            if (y < 10) letra = (y < 2 ? 12 : 8);
            worksheet.mergeCells('A1:B2');
            const response1 = await this.$http.getFile('base64', {
                path: this.user.LogoRoute.replace('Public/', '', null, 'i')
            });

            const divisiones1 = this.user.LogoRoute.split(".");
            const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
            const imageId = workbook.addImage({
                base64: myBase64Image,
                extension: divisiones1[1],
            });
            worksheet.addImage(imageId, 'A1:B2');
            worksheet.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            const rowIndex1 = worksheet.getRow(1);
            rowIndex1.height = 100;

            if(vesselInfo){
                if(companyName){
                    worksheet.mergeCells("C1:" + this.numeroLetra(y) + "2");
                }else if(companyName === undefined){ 
                    worksheet.mergeCells("C1:" + this.numeroLetra(y) + "1");
                    worksheet.mergeCells("C2:" + this.numeroLetra(y) + "2");
                }
            }else{
                if(this.numeroLetra(y) === "B"){
                    worksheet.mergeCells("C1:F2");
                }else{  
                    worksheet.mergeCells("C1:" + this.numeroLetra(y) + "2");
                }
            }
            const customCellCompanyBranch = worksheet.getCell("C1");
            const row1 = worksheet.getRow(1);
            if(vesselInfo){
                row1.height = companyName ? 60 : 30;
            }else{
                row1.height = 60;
            }
            customCellCompanyBranch.font = {
                name: "Calibri",
                family: 4,
                size: vesselInfo ? (letra) : 12,
                underline: false,
                bold: true
            };
            customCellCompanyBranch.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranch.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranch.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

            if(vesselInfo){
                if(companyName){
                }else if(companyName === undefined){     
                    const row2 = worksheet.getRow(2);    
                    row2.height = 30;
                    const customCell = worksheet.getCell("C2");
                    customCell.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'f2f2f2'
                        }
                    }
                    customCell.border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCell.value = this.dataBuque.DatePlanning;
                    customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                }
            }

            //titulo
            worksheet.getRow(3).height = 30;
            worksheet.mergeCells("A3:" + ( this.numeroLetra(y) === "B" ? "F" : this.numeroLetra(y) ) +"3");
            const customCell2 = worksheet.getCell("A3");
            customCell2.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };
            customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            customCell2.value = titulo;
            customCell2.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            //COLUMNA 2
            let division = Math.floor((y === 2 ? 4 : y ) / 3);
            let rango1 = this.numeroLetra(division);
            let rango11 = this.numeroLetra(division+1);
            let rango2 = this.numeroLetra(division*2);
            let rango21 = this.numeroLetra((division * 2) +1);
            let rango3 = this.numeroLetra((y === 2 ? 4 : y ));
            
            const hoy = new Date();

            if(vesselInfo){
                if(companyName){
                    worksheet.mergeCells("A4:" + rango1 +"4");
                    const customCellCompany = worksheet.getCell("A4");
                    worksheet.mergeCells(rango11 + "4:" + rango2 + "4");
                    worksheet.mergeCells(rango21 + "4:" + rango3 + "4");

                    customCellCompany.height = 50;
                    customCellCompany.font = {
                        name: "Calibri",
                        family: 4,
                        size: 11,
                        underline: false,
                        bold: true
                    };

                    customCellCompany.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
                    customCellCompany.value = this.$t('label.company')+": "+companyName;
                    customCellCompany.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                        left: { style: 'thin' },
                    }
                }else if(companyName === undefined){
                    worksheet.mergeCells("A4:" + rango2 +"4"); //fecha de impresion
                    worksheet.mergeCells(rango21 + "4:" + rango3 + "4");  //usuario
                }
            }else{
                worksheet.mergeCells("A4:" + rango2 +"4"); //fecha de impresion
                worksheet.mergeCells(rango21 + "4:" + rango3 + "4");  //usuario
            }

            const customCell6 = vesselInfo ? (companyName ? worksheet.getCell(rango11 + "4") : worksheet.getCell("A4")) : worksheet.getCell("A4");
            customCell6.height = 50;
            customCell6.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };

            customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            customCell6.value = this.$t('label.printed')+": "+hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
            customCell6.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            //usuario
            const customCell7 = vesselInfo ? (companyName ? worksheet.getCell(rango21 + "4") : worksheet.getCell(rango21 + "4")) : worksheet.getCell(rango21 + "4");
            customCell7.height = 50;
            customCell7.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };

            customCell7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            customCell7.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
            customCell7.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            }
                        
            //linea
            worksheet.mergeCells("A5:" + rango3 +"5");
            const customCell8 = worksheet.getCell("A5");
            customCell8.height = 50;
            customCell8.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };
            customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        }

        var headerRow = worksheet.addRow();
        headerRow.font = {
            name: "Calibri",
            family: 4,
            size: 11,
            underline: false,
            bold: true,
        };
    /* headerRow.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
        } */
        headerRow.height = 30;

        let i = 0;
        columnsDefs.map(function (data) {
            let valor = bandera ? data.headerName.length : data.length;
            let long = 10;
            if ((valor >= 5) && (valor <= 12)) {
                long = 20;
            } else if ((valor >= 11) && (valor <= 18)) {
                long = 25;
            } else if ((valor >= 19) && (valor <= 26)) {
                long = 30;
            } else if (valor >= 27) {
                long = 40;
            }

            //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

            worksheet.getColumn(i + 1).width = long;
            //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'CCCCCC'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            
            cell.value = bandera ? data.headerName : data;
            cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' }
            //}        
            i++;
        })
    
        rowData.map(async function (data,indexGeneral) {
            if (data.Cont20FtDeck=="DECK") {
                //Grupo 0
                worksheet.mergeCells("A6:A7");
                worksheet.mergeCells("B6:B7");
                //Grupo 1
                worksheet.mergeCells("C7:F7");
                let cellA4 = worksheet.getCell("D7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = { 
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }            
                cellA4.value = data.Cont20FtDeck;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("G7:J7");
                cellA4 = worksheet.getCell("H7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.Cont20FtHold;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 3
                worksheet.mergeCells("K7:N7");
                cellA4 = worksheet.getCell("L7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.Cont20FtTotal;

                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }

            } else if (data.Cont20Full == "20F") {
                //Grupo 0
                worksheet.mergeCells("A6:A7");
                worksheet.mergeCells("B6:B7");
                worksheet.mergeCells("C6:C7");
                //Grupo 1
                worksheet.mergeCells("D7:E7");
                let cellA4 = worksheet.getCell("D7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.Cont20Full;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("F7:G7");
                cellA4 = worksheet.getCell("F7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.Cont20Empty;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 3
                worksheet.mergeCells("H7:I7");
                cellA4 = worksheet.getCell("H7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.Cont40Full;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 4
                worksheet.mergeCells("J7:K7");
                cellA4 = worksheet.getCell("J7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.Cont40Empty;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 5
                worksheet.mergeCells("L7:M7");
                cellA4 = worksheet.getCell("L7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.Cont45Full;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 6
                worksheet.mergeCells("N7:O7");
                cellA4 = worksheet.getCell("N7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.Cont45Empty;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
            } else if (data.CtaFunctionCode == "CONTACT INFORMATION") {            
                //Grupo 1
                worksheet.mergeCells("N7:P7");
                let cellA4 = worksheet.getCell("N7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.CtaFunctionCode;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("Q7:R7");
                cellA4 = worksheet.getCell("R7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.ComAddressIdentifier;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 3
                worksheet.mergeCells("S7:V7");
                cellA4 = worksheet.getCell("S7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.HanDescriptionCode;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 4
                worksheet.mergeCells("W7:Y7");
                cellA4 = worksheet.getCell("W7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.TsrServicePriorityCode;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
            } else if (data.section=="totalLoad") {
                //Grupo 0
                worksheet.mergeCells("A6:A7");
                worksheet.mergeCells("B6:B7");
                //Grupo 1
                worksheet.mergeCells("C7:E7");
                let cellA4 = worksheet.getCell("C7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = { 
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    },
                }            
                cellA4.value = "DECK";
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("F7:H7");
                cellA4 = worksheet.getCell("F7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = "HOLD";
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
            }else {
                let dataRow = worksheet.addRow();
                let indice = 0;

                keyColumns.map(function (data2) {
                    let cell = dataRow.getCell(indice + 1);
                    cell.value = data[data2];
                    //if (cell.value==)
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    indice++;
                })
                if(data['hijo']){
                    if(data['hijo'].length > 0){
                        let hijoData = data['hijo'];
                        let columnsDefsHijo = Object.keys(hijoData[0]);
                        let keyColumnsHijo = columnsDefsHijo;

                        var headerRowHijo = worksheet.addRow();
                        headerRowHijo.font = {
                            name: "Calibri",
                            family: 4,
                            size: 9,
                            underline: false,
                            bold: true,
                            color: { argb: 'FFFFFF' }
                        };
                        /* headerRowHijo.border = {
                            top: { style: 'thin' },
                            bottom: { style: 'thin' },
                        } */
                        headerRowHijo.height = 30;

                        let i = 0;
                        columnsDefsHijo.map(function (data) {
                            let valor = bandera ? data.headerName.length : data.length;
                            let long = 10;
                            if ((valor >= 5) && (valor <= 12)) {
                                long = 20;
                            } else if ((valor >= 11) && (valor <= 18)) {
                                long = 25;
                            } else if ((valor >= 19) && (valor <= 26)) {
                                long = 30;
                            } else if (valor >= 27) {
                                long = 40;
                            }

                            //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

                            worksheet.getColumn(i + 1).width = long;
                            //if (excel) {
                            let cell = headerRowHijo.getCell(i + 1);
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: {
                                    argb: 'B4B4B4'
                                },
                            }
                            cell.border = {
                                top: { style: 'thin' },
                                bottom: { style: 'thin' },
                                left: { style: 'thin' },
                                right: { style: 'thin' },
                            }
                            
                            cell.value = bandera ? data.headerName : data;
                            cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' }
                            //}        
                            i++;
                        });

                        hijoData.map((item,i) =>{
                            let dataRow = worksheet.addRow();
                            dataRow.font = {
                                name: "Calibri",
                                family: 4,
                                size: 9,
                                underline: false,
                                bold: true,
                            };
                            for (let index = 0; index < columnsDefsHijo.length; index++) {
                                const elementKey = columnsDefsHijo[index];
                                let cell = dataRow.getCell(index + 1);
                                cell.value = item[elementKey];
                                cell.fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: {
                                        argb: 'E8E8E8'
                                    },
                                }
                                cell.border = {
                                    top: { style: 'thin' },
                                    bottom: { style: 'thin' },
                                    left: { style: 'thin' },
                                    right: { style: 'thin' },
                                };
                                cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                            }
                        });

                        if(rowData.length !== (indexGeneral+1)){
                            var headerRowPadre = worksheet.addRow();
                            headerRowPadre.font = {
                                name: "Calibri",
                                family: 4,
                                size: 11,
                                underline: false,
                                bold: true,
                                color: { argb: 'FFFFFF' }
                            };
                            headerRowPadre.height = 30;
                            i = 0;
                            columnsDefs.map(function (data) {
                                let valor = bandera ? data.headerName.length : data.length;
                                let long = 10;
                                if ((valor >= 5) && (valor <= 12)) {
                                    long = 20;
                                } else if ((valor >= 11) && (valor <= 18)) {
                                    long = 25;
                                } else if ((valor >= 19) && (valor <= 26)) {
                                    long = 30;
                                } else if (valor >= 27) {
                                    long = 40;
                                }

                                //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

                                worksheet.getColumn(i + 1).width = long;
                                //if (excel) {
                                let cell = headerRowPadre.getCell(i + 1);
                                cell.fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: {
                                        argb: 'CCCCCC'
                                    }
                                }
                                cell.border = {
                                    top: { style: 'thin' },
                                    bottom: { style: 'thin' },
                                    left: { style: 'thin' },
                                    right: { style: 'thin' },
                                }
                                
                                cell.value = bandera ? data.headerName : data;
                                cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' }
                                //}        
                                i++;
                            });
                        }
                    }
                }
            }
        })

        let fileName = documento;
        let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
        today = today.replaceAll("/", "");
        let time = new Date();
        time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
        //return false;
        if (excel) {
            workbook.xlsx.writeBuffer()
                .then(buffer => {
                    saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                    this.$notify({
                        group: 'container',
                        text: this.$t('label.reportSuccessfully'),
                        type: "success"
                    });
                }).catch(err => {
                    console.log(err);
                })
        } else {
            workbook.csv.writeBuffer()
                .then(buffer => {
                    saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                    this.$notify({
                        group: 'container',
                        text: this.$t('label.reportSuccessfully'),
                        type: "success"
                    });
                })
        }
    }
    async function toggleLevel(item) {
        await this.closeShow(item);
        this.itemsNivelII = item.OrdersJson;
        this.collapseDuration = 300;
        item._toggled = !item._toggled;
        this.$nextTick(() => {
            this.collapseDuration = 0;
        });
    }
    function closeShow(item) {
        let moduleOpen;
        moduleOpen = this.dateFilteredItems.find((val) => val._toggled == true);
        if (moduleOpen != undefined && moduleOpen.StowagePlanningImplementId != item.StowagePlanningImplementId) {
            this.itemsNivelII = [];
            moduleOpen._toggled = !moduleOpen._toggled;
        }
    }
    function getFilteredList(arr) {
        this.filteredList = arr;
    }
    //computeds
    function tableTextHelpers(){
        return {
            tableFilterText: {
                label: this.$t('label.filter'),
                placeholder: this.$t('label.enterSearch'),
            },
            itemsPerPageText: {
                label: this.$t('label.recordsPerPage'),
            },
            noItemsViewText: {
                noResults: this.$t('label.noResultsFound'),
                noItems: this.$t('label.noRecordsAvailble'),
            },
        }
    }
    function dateFilteredItems(){
        if(this.listSelected.length !== 0){
            let list = [];
            let listado = Array;
            listado = this.listSelected;
            if(this.typeArray == 1){
                let locale = this.$i18n.locale;
                list = listado.map((item,index) => Object.assign({}, item, {
                    ...item,
                    RowNumber: index+1,
                    YardName: item.YardName == null ? "" : item.YardName,
                    DriverFull: item.DriverCi&&item.DriverName ? '('+item.DriverCi+') '+item.DriverName : '',
                    emisionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : 'N/A',
                    finalDate: item.FinishDate ? DateFormater.formatDateTimeWithSlash(item.FinishDate) : 'N/A',
                    UltimoUsuario: item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
                    Status: item.Status ? item.Status : 'N/A',
                    OrderStatusDsText: locale == 'es' ? item.OrderStatusDsEs : item.OrderStatusDsEn,
                    /*Status: listado.Status,
                    FgActImpVehAct: listado.FgActImpVehAct,
                    _classes: ( listado.FgActImpVehAct ) ? '' : 'table-danger'*/
                }));
            }else{
                list = listado.map((item,index) => Object.assign({}, item, {
                    ...item,
                    RowNumber: index+1,
                    YardName: item.YardName == null ? "" : item.YardName,
                    ImplementAlias: item.ImplementAlias == null ? "" : item.ImplementAlias,
                    AliasOrigin: item.ImplementAliasOrigin ? item.ImplementAliasOrigin : '',
                    CarrierAlias: item.CarrierAlias == null ? "" : item.CarrierAlias,
                    LicensePlate: item.LicensePlate == null ? "" : item.LicensePlate,
                    ClientName: item.ClientName == null ? "" : item.ClientName,
                    DriverFull: item.DriverCi&&item.DriverName ? '('+item.DriverCi+') '+item.DriverName : '',
                    UltimoUsuario: item.TransaLogin == null ? "" : item.TransaLogin,
                    FormatedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
                    Status: item.Status ? item.Status : 'N/A',
                    _toggled: false,
                    /*Status: listado.Status,
                    FgActImpVehAct: listado.FgActImpVehAct,
                    _classes: ( listado.FgActImpVehAct ) ? '' : 'table-danger'*/
                }));
            }
            return list;
        }
        return [];
    }
    function fields(){
        if(this.typeArray === 1){
            return [
                { key: 'RowNumber', label: '#',filter :false, _style:'width:1%; text-align: center;' },
                { key: 'ImplementAlias', label: this.$t('label.Gamela'),_classes:"center-cell"},
                { key: 'OrderCode', label: this.$t('label.order'),_classes:"center-cell"},
                { key: 'CarrierAlias', label: this.$t('label.transport'),_classes:"center-cell"},
                { key: 'DriverFull', label: this.$t('label.driver'),_classes:"center-cell"},
                { key: 'LicensePlate', label: this.$t('label.vehicle'),_classes:"center-cell"},
                { key: 'emisionDate', label: this.$t('label.D_emission'),_classes:"center-cell"},
                { key: 'finalDate', label: this.$t('label.D_shipping'),_classes:"center-cell"},
                { key: 'TotalTime', label: this.$t('label.time'),_classes:"center-cell"},
                { key: 'UltimoUsuario', label: this.$t('label.user'),_classes:"center-cell",sorter: true },
                { key: 'FormatedDate', label:this.$t('label.date'), _classes:'center-cell',sorter: true},
                { key: 'Observation', label:this.$t('label.observation'), _classes:"center-cell",sorter: true },
                { key: "OrderStatusDsText", label: this.$t("label.status"), _classes: "text-center" },
            ]
        }else{
            return [
                
                { key: 'RowNumber', label: '#',filter :false, _style:'width:1%; text-align: center;' },
                { key: 'ImplementAlias', label: this.$t('label.Gamela'),_classes:"center-cell"},
                { key: 'AliasOrigin', label: this.$t('label.OriginBin'),_classes:"center-cell"},
                { key: 'CarrierAlias', label: this.$t('label.transport'),_classes:"center-cell"},
                { key: 'LicensePlate', label: this.$t('label.vehicle'),_classes:"center-cell"},
                { key: 'DriverFull', label: this.$t('label.driver'),_classes:"center-cell"},
                { key: 'YardName', label: this.$t('label.yard'),_classes:"center-cell"},
                { key: 'ClientName', label: this.$t('label.client'),_classes:"center-cell"},
                { key: 'UltimoUsuario', label: this.$t('label.user'),_classes:"center-cell",sorter: true },
                { key: 'FormatedDate', label:this.$t('label.date'), _classes:'center-cell',_style:'width:10%;',sorter: true},
                { key: 'show_details', label:'',_classes:"center-cell", _style:'width:50px;text-align: center;',sorter: false,filter: false },
            ]
        }

    }
    function fieldslv1and2(){
        return [
            { key: 'RowNumber', label: '#',filter :false, _style:'width:1%; text-align: center;' },
            { key: 'ImplementAlias', label: this.$t('label.Gamela'),_classes:"center-cell"},
            { key: 'OrderCode', label: this.$t('label.order'),_classes:"center-cell"},
            { key: 'CarrierAlias', label: this.$t('label.transport'),_classes:"center-cell"},
            { key: 'DriverFull', label: this.$t('label.driver'),_classes:"center-cell"},
            { key: 'LicensePlate', label: this.$t('label.vehicle'),_classes:"center-cell"},
            { key: 'emisionDate', label: this.$t('label.D_emission'),_classes:"center-cell"},
            { key: 'finalDate', label: this.$t('label.D_shipping'),_classes:"center-cell"},
            { key: 'TotalTime', label: this.$t('label.time'),_classes:"center-cell"},
            { key: 'UltimoUsuario', label: this.$t('label.user'),_classes:"center-cell",sorter: true },
            { key: 'FormatedDate', label:this.$t('label.date'), _classes:'center-cell',_style:'width:10%;',sorter: true},
            { key: "OrderStatusDsText", label: this.$t("label.status"), _classes: "text-center" },
        ]
    }
    function formattedNivel2(){
        if(this.itemsNivelII.length !== 0){
            let itemList = [];
            let locale = this.$i18n.locale;
            itemList = this.itemsNivelII.map((item,Index) => Object.assign({}, item, {
                ...item,
                RowNumber: Index+1,
                YardName: item.YardName == null ? "" : item.YardName,
                DriverFull: item.DriverCi&&item.DriverName ? '('+item.DriverCi+') '+item.DriverName : '',
                emisionDate: item.TransactionDate ? DateFormater.formatOnlyDateWithSlash(item.TransactionDate) : 'N/A',
                finalDate: item.FinishDate ? DateFormater.formatOnlyDateWithSlash(item.FinishDate) : 'N/A',
                UltimoUsuario: item.TransaLogin,
                FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                Status: item.Status ? item.Status : 'N/A',
                OrderStatusDsText: locale == 'es' ? item.OrderStatusDsEs : item.OrderStatusDsEn,
                _classes: '',
            }));
            return itemList;
        }else{
            return [];
        }
    }
    function resetForm(event) {
        this.$emit('updated-modal', event);
    }
    export default {
        name:'modal-historico-asignacion',
        data,
        props: {
            title: String,
            modal: null,
            listSelected: {
                type: Array,
                required: true,
                default: () => [],
            },
            typeArray:{
                type: Number,
                required: true,
                default: 1
            },
            objectAsignacion: Object,
        },
        mixins: [GeneralMixin, ModalMixin,Reportes ],
        methods:{
            resetForm,
            onBtnExport,
            toggleLevel,
            closeShow,
            getExcelArray2,
            StatusColor,
            getFilteredList
        },
        computed:{
            tableTextHelpers,
            dateFilteredItems,
            fields,
            fieldslv1and2,
            formattedNivel2,
            nameGamela(){
                if (Object.keys(this.objectAsignacion).length !== 0) {
                    return this.objectAsignacion.ImplementAlias;
                }
                return '';
            },
        },
        mounted(){
            this.toggle(false);
        },
        watch: {
            modal: function(val) {
                this.modalActive = val;
            },
        }
    }
</script>7

<style lang="scss">
    .modal-content-asignacion {
        .modal-xl{
            max-width: 90%;
            .modal-content {
                width: 100% !important;
                margin: auto !important;
                .modal-body {
                    padding-left: 1.7rem;
                    padding-right: 1.7rem;
                }
            }
        }
    }
    .status-color{
        font-size: 75%;
        font-weight: 700;
    }
</style>