<template>
    <CRow style="margin-top: 20px">
        <CCol sm="12">
            <CTabs variant="tabs" :active-tab="tabChange" @update:activeTab="handleTab">
                <CTab :title="$t('label.transport')"  class="form-especial">
                    <indexTransporte :value="tabChange" @child-refresh="transferir=false" />
                </CTab>
                <CTab :title="$t('label.drivers')" class="form-especial">
                    <indexChoferes  :value="tabChange" @child-refresh="transferir=false" />
                </CTab>
                <CTab :title="$t('label.vehicle')+'S'" class="form-especial">
                    <indexVehiculos  :value="tabChange" @child-refresh="transferir=false" />
                </CTab>
                <CTab :title="$t('label.Gamela')+'S'" class="form-especial" v-if="!FgGeneralCargo">
                    <indexGamela  :value="tabChange" @child-refresh="transferir=false" />
                </CTab>
                <CTab :title="$t('label.AssociationGamela')" class="form-especial" v-if="!FgGeneralCargo">
                    <indexAsignacionGamela  :value="tabChange" @child-refresh="transferir=false" />
                </CTab>
                <CTab :title="$t('label.historic')" class="form-especial" v-if="!FgGeneralCargo">
                    <indexHistoric  :value="tabChange" @child-refresh="transferir=false" />
                </CTab>
            </CTabs>
        </CCol>
    </CRow>
</template>

<script>
    import { mapState } from "vuex";
    import indexTransporte from './transporte';
    import indexChoferes from './choferes';
    import indexVehiculos from './vehiculos';
    import indexGamela from './gamela';
    import indexAsignacionGamela from './asignacion-gamela';
    import indexHistoric from './historic';
    //data
    function data() {
        return {
            tabChange: 8,
        }
    }
    //methods
    function handleTab(tab) {
        this.tabChange = tab;
    }
    //computed
    function transferir(){
        return this.tabChange === 0;
    }
    export default {
        name:'index-logistica',
        data,
        methods:{
            handleTab,
        },
        computed:{
            transferir,
            ...mapState({
                tabIndex: (state) => state.planificacionestiba.tabIndex,
                FgGeneralCargo: state => state.planificacionestiba.FgGeneralCargo,
                collapsePlanificacion: state => state.planificacionestiba.collapsePlanificacion
            })
        },
        watch:{
            tabIndex(newValue,OldValue){
                if(newValue === 8){
                    this.tabChange = 0;
                }
                if(OldValue === 8){
                    this.tabChange = 8;
                }
            },
        },
        components:{
            indexTransporte,
            indexChoferes,
            indexVehiculos,
            indexGamela,
            indexAsignacionGamela,
            indexHistoric
        }
    }
</script>