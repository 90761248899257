<template>
    <main>
        <CModalExtended
            :title="title"
            color="dark"
            :show.sync="modalActive"
            :closeOnBackdrop="false"
        >
            <loading-overlay
                :active="loadingOverlay"
                :is-full-page="true"
                loader="bars"
            />
            <CRow >
                <CCol sm="12" lg="12" style="margin-top:10px;">
                    <CSelect
                        :label="$t('label.client')"
                        :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-8'}"
                        addLabelClasses="required"
                        :options="companyOptions"
                        :value.sync="ClientTpId"
                        :is-valid="hasError($v.ClientTpId)"
                        v-model.trim="$v.ClientTpId.$model"
                        :invalid-feedback="errorMessage($v.ClientTpId)"
                    ></CSelect>
                </CCol>
                <CCol sm="12" lg="12" style="margin-top:10px;">
                    <CSelect
                        :label="$t('label.Gamela')"
                        :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-8'}"
                        :options="gamelasOptions"
                        :value.sync="ImplementId"
                        :is-valid="hasError($v.ImplementId)"
                        v-model.trim="$v.ImplementId.$model"
                        :invalid-feedback="errorMessage($v.ImplementId)"
                    ></CSelect>
                </CCol>
                <CCol sm="12" lg="12" style="margin-top:10px;">
                    <CInput  
                        addLabelClasses="required"
                        :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-8'}"
                        v-uppercase
                        maxlength="20"
                        :label="$t('label.alias')"
                        :placeholder="$t('label.alias')"
                        :is-valid="hasError($v.ImplementAlias)"
                        v-model.trim="$v.ImplementAlias.$model"
                        :invalid-feedback="errorMessage($v.ImplementAlias)"
                    />
                </CCol>
                <CCol sm="12" lg="12" style="margin-top:10px;" v-if="editModal">
                    <CSelect
                        :label="$t('label.status')"
                        :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-8'}"
                        v-uppercase
                        :options="optionsStatus"
                        :invalid-feedback="errorMessage($v.Status)"
                        :is-valid="statusSelectColor()"
                        v-model.trim="$v.Status.$model"
                        :value.sync="Status"        
                    />
                </CCol>
            </CRow>
            <template #footer>
                <CButton
                    outline
                    color="add"
                    :disabled="isSubmit"
                    @click="editModal?statusConfirmation(originStatus, Status, submit,ImplementAlias):submit()"  
                >
                    <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
                </CButton>
                <CButton
                    color="wipe"
                    class="d-flex align-items-center"
                    :disabled="isSubmit"
                    @click.stop="toggle(false)"
                >
                    <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
                </CButton>
            </template>
        </CModalExtended>
    </main>
</template>

<script>
    import GeneralMixin from '@/_mixins/general';
    import UpperCase  from '@/_validations/uppercase-directive';
    import { gamela } from '@/_validations/planificacion-estiba/logistica-empresa';
    import ModalMixin from '@/_mixins/modal';
    import { mapState } from 'vuex';
    function data(){
        return{
            companyOptions:[],
            gamelasOptions:[],
            editar: false,
            //registro+
            modalActive:false,
            StowagePlanningImplementId:'',
            ClientTpId:"",
            ImplementAlias:'',
            ImplementId:"",
            seleccionar:1,
            optionsStatus: [
                { label: this.$t('label.ACTIVO'), value: 1 },
                { label: this.$t('label.INACTIVO'), value: 0 },
            ],
            isSubmit: false,  
            originStatus: '',
            Status: 1,
            loadingOverlay:false,
        }
    }
    //methods
    async function listarGamelas () {
        this.loadingOverlay = true;
        this.companyOptions = [];

        let listado = Array;
        if (this.editModal) {
            await this.$http.get("StowagePlanningClientConsigneImplementUpdate-list", { StowagePlanningImplementId:this.gamelaSelected.StowagePlanningImplementId })
            .then(response => {
                listado = [...response.data.data];
                this.companyOptions = listado.map(listado => Object.assign({}, this.companyOptions, {
                    value: listado.ClientTpId,
                    label: listado.ClientName,
                    json: listado.ImplementJson
                }));

                this.companyOptions.unshift({
                    value: "",
                    label: this.$t('label.select')
                });

                if(this.modalActive){
                    let x = this.companyOptions.map(function(e) {
                        return e.value; 
                    }).indexOf(this.ClientTpId);
                    if(x !== -1){
                        let listadoImplement = this.companyOptions[x].json;
                        this.gamelasOptions = listadoImplement.map(listado => Object.assign({}, listadoImplement, {
                            value: listado.ImplementId,
                            label: listado.ImplementAlias,
                        }));

                        this.gamelasOptions.unshift({
                            value: "",
                            label: this.$t('label.select')
                        });
                    }
                }
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
            .finally(() => {
                this.loadingOverlay = false;
            });
        } else {
            await this.$http.get("StowagePlanningClientConsigneFreeImplement-list", { StowagePlanningId:this.StowagePlanningId })
            .then(response => {
                listado = [...response.data.data];
                this.companyOptions = listado.map(listado => Object.assign({}, this.companyOptions, {
                    value: listado.ClientTpId,
                    label: listado.ClientName,
                    json: listado.ImplementJson
                }));

                this.companyOptions.unshift({
                    value: "",
                    label: this.$t('label.select')
                });

                this.gamelasOptions = [{
                    value: "",
                    label: this.$t('label.select')
                }]
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
            .finally(() => {
                this.loadingOverlay = false;
            });
        }
        
    }
    function resetForm(event){
        this.StowagePlanningImplementId = "";
        this.ClientTpId = "";
        this.ImplementAlias = "";
        this.ImplementId = "";
        this.$v.$reset();
        this.$emit('updated-modal', event);
    }
    async function submit(){
        try {
            this.$v.$touch();
            if (this.$v.$error) {
                //this.loadingOverlay = false;
                throw this.$t('label.errorsPleaseCheck');
            }
            const StowagePlanningImplementJson = {
                ClientTpId:this.ClientTpId,
                ImplementId: this.ImplementId,
                StowagePlanningId: this.StowagePlanningId,
                ImplementAlias: this.ImplementAlias,
                Status: 1
            };
            this.loadingOverlay = true;
            let editTipo = this.StowagePlanningImplementId ? true : false;
            let method = editTipo ? 'PUT':'POST';
            let ruta = editTipo ? 'StowagePlanningImplement-update':'StowagePlanningImplement-insert';
            if(editTipo){
                StowagePlanningImplementJson.StowagePlanningImplementId = this.StowagePlanningImplementId;
                StowagePlanningImplementJson.Status= this.Status;
            }
            await this.$http.ejecutar (method, ruta, StowagePlanningImplementJson, { root: 'StowagePlanningImplementJson' }).then((response) => {
                if (response.status === (200 || 201)) {
                    const messageSuccess = response.data.data[0].Response;
                    this.$emit('refresh-data-table');
                    this.loadingOverlay = false;
                    this.isSubmit = false;
                    this.toggle(false);
                    this.notifySuccess({ text: messageSuccess });
                }
            })
            .catch((e) => {
                this.loadingOverlay = false;
                this.isSubmit = false;
                this.notifyError({ text: e });
            }).then(() => {
                this.isSubmit = false;
                this.loadingOverlay = false;
            });
        } catch (e) {
            this.loadingOverlay = false;
            this.isSubmit = false;
            this.notifyError({ text: e });
        }
    }
    export default {
        name:'modal-gamela',
        data,
        props: {
            title: String,
            modal: null,
            editModal: Boolean,
            gamelaSelected: Object,
        },
        mixins: [GeneralMixin, ModalMixin, ],
        directives: UpperCase,
        validations: gamela,
        mounted(){
            this.toggle(false);
        },
        methods:{
            resetForm,
            submit,
            listarGamelas,
        },
        computed:{
            ...mapState({
                StowagePlanningId: state => state.planificacionestiba.planificacionId,
                tabIndex: state => state.planificacionestiba.tabIndex,
                TpVessel: state => state.planificacionestiba.TpVesselId,
                user: state => state.auth.user,
            })
        },
        watch:{
            modal: function(val) {
                this.modalActive = val;
                if(val){
                    this.listarGamelas();
                }
                if(this.editModal){
                    this.$v.$touch();
                }
            },
            gamelaSelected: function(val) {
                if (Object.keys(val).length !== 0) {
                    this.seleccionar = 2;
                    this.StowagePlanningImplementId = val.StowagePlanningImplementId;
                    this.ImplementId = val.ImplementId;
                    this.ClientTpId = val.ClientTpId;
                    
                    this.ImplementAlias = val.ImplementAlias;
                    this.originStatus = val.Status === 'ACTIVO' ? 1 : 0;
                    this.Status = val.Status === 'ACTIVO' ? 1 : 0;
                }
            },
            ClientTpId: function(newValue){
                if(newValue==""|| newValue==null){
                    if(this.seleccionar == 1){
                        this.ImplementId = "";
                    }
                    this.gamelasOptions = [{
                        value: "",
                        label: this.$t('label.select')
                    }]
                }else{
                    let x = this.companyOptions.map(function(e) {
                        return e.value; 
                    }).indexOf(newValue);
                    if(x === -1){
                        if(this.seleccionar == 1){
                            this.ImplementId = "";
                        }
                        this.gamelasOptions = [{
                            value: "",
                            label: this.$t('label.select')
                        }];
                    }else{
                        if(this.seleccionar == 1){
                            this.ImplementId = "";
                        }
                        let listadoImplement = this.companyOptions[x].json;
                        this.gamelasOptions = listadoImplement.map(listado => Object.assign({}, listadoImplement, {
                            value: listado.ImplementId,
                            label: listado.ImplementAlias,
                        }));

                        this.gamelasOptions.unshift({
                            value: "",
                            label: this.$t('label.select')
                        });
                    }
                }
            },
            ImplementId:function(newValue){
                if(this.seleccionar == 1){
                    if(newValue==""|| newValue==null){
                        this.ImplementAlias = "";
                    }else{
                        if(this.gamelasOptions.length > 0){
                            let x = this.gamelasOptions.map(function(e) {
                                return e.value; 
                            }).indexOf(newValue);
                            if(x === -1){
                                this.ImplementAlias = "";
                            }else{
                                this.ImplementAlias = this.gamelasOptions[x].label;
                            }
                        }
                    }
                }else{
                    this.seleccionar = 1;
                }
            }
        }
    }
</script>