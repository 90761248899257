<template>
    <div class="container-fluid mb-4" style="margin-top:20px;">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <modalGamela
            :modal.sync="showModalGamela"
            @updated-modal="updateCLoseModal"
            :title="titleModal"
            :editModal="editModal"
            @refresh-data-table="resetDataOfTable"
            :gamelaSelected="GamelaSelected"
        />
        <CRow>
            <CCol sm="12" class="d-flex align-items-start justify-content-end">
                <CButton
                    color="add"
                    size="sm"
                    @click="toggleShowModalCreateGamela()"
                    v-c-tooltip="{ placement:'top', content:$t('label.Gamela') }"
                    >
                    <CIcon name="cil-playlist-add"/>&nbsp; {{ $t('label.nueva') }}
                </CButton>
            </CCol>
            <CCol sm="12">
                <dataTableExtended
                class="align-center-row-datatable"
                    size="lg"
                    :fields="fields"
                    :items="items"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    :loading="loadingTable"
                    hover
                    sorter
                    >
                    <template #loading>
                        <loading/>
                    </template>
                    <template #Status="{ item }">
                        <td class="text-center align-middle">
                            <div>
                                <CBadge :color="getBadge(item.Status)">
                                {{ $t("label." + item.Status) }}
                                </CBadge>
                            </div>
                        </td>
                    </template>
                    <template #Detalle="{ item }">
                        <td class="text-center">
                            <CButton color="edit" size="sm" v-c-tooltip="{content: $t('label.edit')+' '+$t('label.Gamela'),placement:'top-end'}" @click="toggleShowModalEditGamela(item)">
                                <CIcon name="pencil" />
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
    </div>
</template>
<script>
    import { DateFormater, alertPropertiesHelpers } from '@/_helpers/funciones';
    import GeneralMixin from '@/_mixins/general';
    import { mapState } from 'vuex';
    
    import modalGamela from './modal-gamela.vue';
    function data() {
        return {
            loadingTable:false,
            loadingOverlay:false,
            items: [],
            showModalGamela:false,
            titleModal:'',
            editModal:false,
            refrescarTabla:false,
            GamelaSelected:{}
        }
    }
    function toggleShowModalCreateGamela() {
        this.showModalGamela = true;
        this.titleModal = this.$t('label.nueva')+' '+this.$t('label.Gamela');
    }
    function ConfirmarEliminado(item) {
        this.$swal.fire(
        alertPropertiesHelpers(this, {
            text: `${this.$t('label.deleteQuestion')}: ${item.ImplementAlias}?`,
        })
        ).then((result) => {
            if (result.isConfirmed) {
                let res = [];
                this.loadingOverlay = true;
                let StowagePlanningImplementJson = {
                    StowagePlanningImplementId:item.StowagePlanningImplementId,
                    StowagePlanningId: item.StowagePlanningId,
                    ImplementId:item.ImplementId,
                    ClientTpId:item.ClientTpId,
                    ImplementAlias:item.ImplementAlias,
                    Status:0
                };
                this.$http.put("StowagePlanningImplement-update", StowagePlanningImplementJson, { root: 'StowagePlanningImplementJson' })
                .then(response => {
                    res = [...response.data.data];
                    this.$notify({
                        group: 'container',
                        title: '¡Exito!',
                        text: res[0].Response,
                        type: "success"
                    });
                    this.loadingOverlay = false;
                    this.StowagePlanningImplementList();
                }).catch((err) => {
                    this.$notify({
                        group: 'container',
                        title: '¡Error!',
                        text: err,
                        type: "error"
                    });
                    this.loadingOverlay = false;
                });
            }
        });
    }
    function getBadge(status) {
        switch (status) {
        case 'ACTIVO':
            return 'success'
        case 'INACTIVO':
            return 'danger'
        }
    }
    async function StowagePlanningImplementList () {
        this.loadingTable = true;
        this.items = [];
        
        let listado = Array;
        await this.$http.get("StowagePlanningImplement-list", { StowagePlanningId: this.StowagePlanningId })
        .then(response => {
            listado = [...response.data.data];
            this.items = listado.map(listado => Object.assign({}, this.items, {
                ...listado,
                Nro: Number(listado.Nro),
                UltimoUsuario: listado.TransaLogin,
                FormatedDate: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
                Status: listado.Status,
                FgActStoPlaImp: listado.FgActStoPlaImp,
                _classes: ( listado.FgActStoPlaImp ) ? '' : 'table-danger'
            }));
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingTable = false;
        });
    }
    function toggleShowModalEditGamela(item) {
        this.showModalGamela = true;
        this.GamelaSelected = item;
        this.titleModal = this.$t('label.edit')+' '+this.$t('label.Gamela')+': '+item.ImplementName;
        this.editModal = true;
    }
    function resetDataOfTable() {
        this.showModalGamela = false;
        this.refrescarTabla = true;
    }
    function updateCLoseModal(event) {
        this.showModalGamela = false;
        this.titleModal = '';
        this.editModal = false;
        this.GamelaSelected = {};
    }
    //computeds
    function idIndentification(){
        return this.StowagePlanningImplementId == "";
    }
    function fields(){
        return [
            { 
                key: 'Nro', 
                label: '#',
                _style: 'width:3%; text-align:center;', filter: false, _classes: 'text-center',sorter: true
            },
            { key: 'ImplementName', label: "GAMELA", _style: 'width:20%;',_classes:"center-cell",sorter: true },
            { key: 'ImplementAlias', label: this.$t('label.alias'), _style: 'width:13%;',_classes:"center-cell",sorter: true },
            { key: 'ClientName', label: this.$t('label.client'),_style: 'width:20%;',_classes:"center-cell",sorter: true },
            { key: 'UltimoUsuario', label: this.$t('label.user'),_style: 'width:13%;',_classes:"center-cell",sorter: true, },
            { key: 'FormatedDate', label:this.$t('label.date'), _style: 'width:13%;',_classes:'center-cell',_style:'width:13%;',sorter: true},
            { key: "Status", label: this.$t("label.status"), _style: 'width:13%;',_classes: "text-center" },
            { 
                key: 'Detalle',
                label: '',
                sorter: false,
                filter: false,
                _style: 'width:1%; min-width:45px;'
            }
        ];
    }
    export default {
        name:'gamela',
        data,
        props:['value'],
        mixins: [GeneralMixin],
        methods:{
            toggleShowModalCreateGamela,
            ConfirmarEliminado,
            StowagePlanningImplementList,
            toggleShowModalEditGamela,
            getBadge,
            resetDataOfTable,
            updateCLoseModal
        },
        computed:{
            idIndentification,
            fields,
            ...mapState({
                StowagePlanningId: state => state.planificacionestiba.planificacionId,
                tabIndex: state => state.planificacionestiba.tabIndex,
                TpVessel: state => state.planificacionestiba.TpVesselId,
                user: state => state.auth.user,
            })
        },
        components:{
            modalGamela
        },
        watch:{
            value: function (newValue,OldValue) {
                if ((newValue==3) && (this.StowagePlanning!="")) {
                    this.StowagePlanningImplementList();
                }
            },
            refrescarTabla: function () {
                if (this.refrescarTabla) {
                    this.StowagePlanningImplementList();
                    this.refrescarTabla = false;
                }
            },
        }
    }
</script>